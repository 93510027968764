const initialState = () => ({
    content: []
});

const getters = {
    getContent(state) {
        return state.content;
    },
    getInfoBanner(state) {
        return {
            infoBanner: {
                content: state.content?.infoBannerWrapper[0]?.fields?.longText,
                showOnLogin: state.content?.infoBannerWrapper[0]?.metadata?.tags?.some((elt) => elt.sys.id === "showOnLogin"),
                showOnOffer: state.content?.infoBannerWrapper[0]?.metadata?.tags?.some((elt) => elt.sys.id === "showOnOffer")
            }
        };
    }
};

const mutations = {
    setContent(state, data) {
        state.content = {
            ...state.content, ...data
        };
    },
    resetStore(state) {
        const s = initialState();
        Object.keys(s).forEach((key) => {
            state[key] = s[key];
        });
    }
};

const state = initialState();

export default {
    getters,
    mutations,
    namespaced: true,
    state
};
