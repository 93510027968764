// Define initial state setup
const initialState = () => ({
    offersToCompare: [],
    offerDefaultGalleryImages: null
});

// define getters
const getters = {
    getOffersToCompare(state) {
        return state.offersToCompare;
    },
    getOfferDefaultGalleryImages(state) {
        return state.offerDefaultGalleryImages;
    }
};

// define actions
const actions = {
    setOffersToCompare({ commit }, id) {
        return new Promise((resolve) => {
            commit("setOffersToCompare", { id, callback: resolve });
        });
    },
    removeIdFromOffersToCompare({ commit }, id) {
        return new Promise((resolve) => {
            commit("removeIdFromOffersToCompare", { id, callback: resolve });
        });
    }
};

// define mutations
const mutations = {
    setOfferDefaultGalleryImages(state, payload) {
        state.offerDefaultGalleryImages = [...payload.images];
    },
    setOffersToCompare(state, { id, callback }) {
        if (state.offersToCompare.indexOf(id) < 0) {
            state.offersToCompare.push(id);
        } else {
            state.offersToCompare.splice(state.offersToCompare.indexOf(id), 1);
        }
        callback(state.offersToCompare);
    },
    removeIdFromOffersToCompare(state, { id, callback }) {
        state.offersToCompare.splice(state.offersToCompare.indexOf(id), 1);
        callback(state.offersToCompare);
    },
    resetStore(state) {
        const s = initialState();
        Object.keys(s).forEach((key) => {
            state[key] = s[key];
        });
    }
};

// export namespaced store module
export default {
    actions,
    getters,
    mutations,
    namespaced: true,
    state: initialState()
};
