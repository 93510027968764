import moment from "moment";

require("moment/min/locales.min");

export default {

    methods: {
        /**
         * @description get specific parameters out of the url
         * @param {string} param
         * @returns {(boolean|string)} false or string
         */
        getParams(param) {
            const query = window.location.search.substring(1);
            const vars = query.split("&");
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < vars.length; i++) {
                const pair = vars[i].split("=");

                if (pair[0] === param) {
                    return pair[1] || true;
                }
            }

            return false;
        },

        /**
         * @description format price
         * @param {string} value
         * @return {string}
         */
        formatPrice(value) {
            const val = (value / 1).toFixed(2).replace(".", ",");
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".").replace(/,00/, ",–");
        },

        /**
         * @description format price and add VAT
         * @param {string} value
         * @return {string}
         */
        formatPriceWithVAT(value) {
            const vatFactor = 1.19;
            value *= vatFactor;
            const val = (value / 1).toFixed(2).replace(".", ",");
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".").replace(/,00/, ",–");
        },

        /**
         * @description Is the selected element in the viewport ?
         * @param {string} elem
         * @return {boolean}
         */
        isInViewport(elem) {
            const bounding = elem.getBoundingClientRect();
            return (
                bounding.top >= 0
                && bounding.left >= 0
                && bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)
                && bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
            );
        },

        /**
         * @description Format date into requested format
         * @param {string} date - containing the unformatted date
         * @param {string} formatPattern - the expected output format
         * @return {string}
         */
        formatDate(date, formatPattern = "DD.MM.YYYY") {
            moment.locale("de");
            return moment(date).format(formatPattern);
        },

        /**
         * @description Check if date is in transformationPeriod
         * @return {boolean}
         */
        isTemporaryClose() {
            const from = new Date("2022/11/01");
            const currentDate = new Date();
            return currentDate >= from;
        },

        /**
         * @description Try to resend activation mail
         * @return {insertNotification}
         */
        async resendActivationMail() {
            const responseResendActivationMail = await this.apiRequest(
                "post",
                "/api/user/registration/resend-activation-mail",
                { email: this.email }
            );
            return this.insertNotification(
                responseResendActivationMail.status === 200
                    ? this.$successMessages.resendActivationMail
                    : this.$errorMessages.resendActivationMail,
                responseResendActivationMail.status === 200
                    ? "success"
                    : "error",
                responseResendActivationMail.status === 200
            );
        },

        /**
         * description: Transform efficiency-class to label.
         * @param {String} eClass
         * @returns {String}
         */
        getEmissionLabel(eClass) {
            switch (eClass.toString()) {
            case "A_PLUS_PLUS_PLUS":
            case "3":
                return "A+++";
            case "A_PLUS_PLUS":
            case "2":
                return "A++";
            case "A_PLUS":
            case "+":
                return "A+";
            default:
                return eClass;
            }
        },

        /**
         * @description get tooltip content
         * @param {string} entryId - entry id in contentful
         * @return {string}
         */
        getTooltipContent(entryId) {
            return this.$store.getters["ContentStore/getContent"]?.tooltips
                .filter((el) => el.sys.id === entryId)?.[0]?.fields.shortText;
        }
    }
};
