const apiRequest = require("@/mixins/apiRequest.mixin")?.default?.methods?.apiRequest;

const initialState = () => ({
    orders: []
});

// define getters
const getters = {
    GET_ORDER: (state) => (id) => state.orders.find((order) => order.id === +id)
};

// define actions
const actions = {
    async FETCH_ORDERS({ commit, rootGetters }) {
        const response = await apiRequest("get", "/api/user/order/", {}, rootGetters["AuthenticationStore/getAuthToken"]);
        const orders = response?.data;
        if (orders.length > 0) {
            return new Promise((resolve) => {
                commit("SET_ORDERS", { orders, callback: resolve });
            });
        }
        return false;
    }
};

// define mutations
const mutations = {
    SET_ORDERS(state, payload) {
        state.orders = [
            ...payload.orders
        ];
        return payload.callback(state.orders);
    },
    resetStore(state) {
        const s = initialState();
        Object.keys(s).forEach((key) => {
            state[key] = s[key];
        });
    },
    UPDATE_TAXATION_DATA(state, payload) {
        // eslint-disable-next-line array-callback-return
        state.orders.filter(((order) => {
            if (order.id === +payload.id) {
                order.taxInformation = {
                    ...payload
                };
            }
        }));
    }
};

// export namespaced store module
export default {
    actions,
    getters,
    mutations,
    namespaced: true,
    state: initialState()
};
