import resetStoreMixin from "@/mixins/resetStore.mixin";

/**
* @description Logout User who is inactive for >= 60 Minutes
* @param {object} router - vue-router object
* @param {object} store - vuex-store object
* @return {void}
*/
// eslint-disable-next-line consistent-return
const handleSession = (router, store) => {
    if ((store.getters["AuthenticationStore/getLastActivityInMinutes"]) >= 60) {
        resetStoreMixin.methods.resetStore(store);
        return router.push("/?view=login&session_expired");
    }
};

// Define initial state setup
const initialState = () => ({
    authToken: null,
    refreshToken: null,
    lastActivity: new Date(),
    now: new Date()
});

// define getters
const getters = {
    getAuthToken(state) {
        return state.authToken;
    },
    getRefreshToken(state) {
        return state.refreshToken;
    },
    getLastActivityInMinutes(state) {
        const diffInMinutes = Math.floor(
            ((new Date(state.now) - new Date(state.lastActivity)) / 1000) / 60
        );
        return diffInMinutes < 0 ? 0 : diffInMinutes;
    }
};

// define mutations
const mutations = {
    setAuthToken(state, token) {
        state.authToken = token;
    },
    setTokenPair(state, payload) {
        state.authToken = payload.authToken;
        state.refreshToken = payload.refreshToken;
    },
    updateLastActivity(state) {
        state.lastActivity = new Date();
    },
    resetStore(state) {
        const s = initialState();
        Object.keys(s).forEach((key) => {
            state[key] = s[key];
        });
    },
    updateTime(state, payload) {
        state.now = new Date();
        if (state.lastActivity && state.authToken) {
            handleSession(payload.router, payload.store);
        }
    }
};

// define actions
const actions = {
    start({ commit }, payload) {
        window.sessionInterval = setInterval(() => {
            commit("updateTime", payload);
        }, 10 * 60000);
    }
};

// export namespaced store module
export default {
    actions,
    getters,
    namespaced: true,
    mutations,
    state: initialState()
};
