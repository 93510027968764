<template lang="pug">
    #app
        cCookieLayer(
            host="https://meinauto.de"
        )
        VueExtendLayouts(path="layouts")
</template>

<script>

// External Dependencies
import VueExtendLayouts from "vue-extend-layout";
import cCookieLayer from "@/components/cookie-layer/CookieLayer.vue";
import * as Sentry from "@sentry/browser";

export default {
    name: "App",
    components: { VueExtendLayouts, cCookieLayer },
    data() {
        return {
            isLoading: false
        };
    },
    beforeMount() {
        // Load Contentful content
        this.$MaContentfulApi
            .byEntryId("4bJRKMWpbTp42RCvIImHzG")
            .getClient()
            .fetch()
            .then((res) => {
                if (res?.items?.length > 0) {
                    this.$store.commit("ContentStore/setContent", res?.items[0]?.fields);
                }
                throw res;
            })
            .catch((e) => {
                // Log Error
                Sentry.withScope((scope) => {
                    scope.setExtra("Status", e.response?.status);
                    scope.setExtra("Data", JSON.stringify(e.response?.data));
                    Sentry.captureMessage(
                        "Error fetching content from contentful",
                        "error"
                    );
                });
            });
    },
    created() {
        if (!window.sessionInterval && this.$store.getters["AuthenticationStore/getAuthToken"] !== null && this.$store.getters["AuthenticationStore/getAuthToken"] !== "") {
            this.$store.dispatch("AuthenticationStore/start", { router: this.$router, store: this.$store });
        }
    }
};
</script>
