const initialState = () => ({
    email: null,
    userWhitelisting: {},
    personalInformation: {},
    hasOrder: false,
    canPlaceOrder: true,
    canNotPlaceOrderBecause: ""
});

// define getters
const getters = {
    getFirstName(state) {
        return state.personalInformation.firstName;
    },
    getAccountData(state) {
        return state;
    },
    getUserOrderStatus(state) {
        return state.hasOrder;
    },
    getUserCanPlaceOrder(state) {
        return state.canPlaceOrder;
    },
    getUserCanNotPlaceOrderBecause(state) {
        return state.canNotPlaceOrderBecause;
    },
    getCompanyAddress(state) {
        return {
            street: state.userWhitelisting?.company?.street,
            streetNumber: state.userWhitelisting?.company?.streetNumber,
            zip: state.userWhitelisting?.company?.zip,
            city: state.userWhitelisting?.company?.city
        };
    }
};

// define mutations
const mutations = {
    setAccountData(state, payload) {
        state.email = payload.accountData.email;
        state.userWhitelisting = {
            ...payload.accountData.userWhitelisting
        };
        state.personalInformation = {
            ...payload.accountData.personalInformation
        };
        state.hasOrder = payload.accountData.hasOrder;
        state.canPlaceOrder = payload.accountData.canPlaceOrder;
        state.canNotPlaceOrderBecause = payload.accountData.canNotPlaceOrderBecause;
    },
    setUserOrderStatus(state, payload) {
        return state.hasOrder = payload.data.orderStatus;
    },
    setUserCanPlaceOrder(state, payload) {
        return state.canPlaceOrder = payload.canPlaceOrder;
    },
    setPersonalInformation(state, payload) {
        return state.personalInformation = {
            ...payload.data.personalInformation
        };
    },
    resetStore(state) {
        const s = initialState();
        Object.keys(s).forEach((key) => {
            state[key] = s[key];
        });
    }
};

// export namespaced store module
export default {
    getters,
    mutations,
    namespaced: true,
    state: initialState()
};
