/* eslint-disable no-console */
import { sync } from "vuex-router-sync";
import Configuration from "@/config/config";
import * as Sentry from "@sentry/browser";
import * as Integrations from "@sentry/integrations";
import VTooltip from "v-tooltip";
import Vuelidate from "vuelidate";
import VueLazyload from "vue-lazyload";
import Vue from "vue";
import { MaContentfulApi } from "@meinauto-gmbh/services";
import packageJson from "../package.json";
import App from "./App.vue";
import { createRouter } from "./router";
import { createStore } from "./store";

// Tooltip
Vue.use(VTooltip);

// Vuelidate
Vue.use(Vuelidate);

// Image lazy load
Vue.use(VueLazyload, {
    preLoad: 1.3,
    // eslint-disable-next-line global-require
    error: require("./assets/img/no-picture.svg"),
    attempt: 1,
    adapter: {
        loaded: ({ el }) => {
            el.parentElement.classList.add("modelInfo");
        }
    }
});

// -------------------------------- Sentry ----------------------------------- //
const packageVersion = `${packageJson.name}@${packageJson.version}`;
const sentryLogger = Configuration.value("VUE_APP_SENTRY_LOGGER");
const sentryEnabled = Configuration.value("VUE_APP_SENTRY_ENABLED") === "true";
let environment = Configuration.value("VUE_APP_ENVIRONMENT");

if (sentryEnabled) {
    // eslint-disable-next-line no-restricted-globals
    if (location.hostname === "localhost") {
        environment = "local";
    }
    Sentry.init({
        release: packageVersion,
        dsn: sentryLogger,
        environment,
        integrations: [
            new Integrations.Vue({
                Vue,
                attachProps: true
            })
        ]
    });
}

// ----------------------------------------------------------------------------- //

Vue.config.productionTip = false;

// noinspection JSValidateJSDoc
/**
 * @returns {{app: (Vue | CombinedVueInstance<Vue, object, object, object, Record<never, any>>)}}
 */
// eslint-disable-next-line import/prefer-default-export
export function createApp() {
    const router = createRouter();
    // eslint-disable-next-line global-require
    if (require("storage-available")("localStorage")) {
        const store = createStore();

        sync(store, router);

        // eslint-disable-next-line global-require
        Vue.prototype.$errorMessages = require("./appMessages").appMessages.errorMessages;
        // eslint-disable-next-line global-require
        Vue.prototype.$successMessages = require("./appMessages").appMessages.successMessages;

        // Init Contentfull Api
        Vue.prototype.$MaContentfulApi = new MaContentfulApi({
            accessToken: Configuration.value("VUE_APP_CONTENTFUL_ACCESS_TOKEN"),
            host: Configuration.value("VUE_APP_CONTENTFUL_HOST"),
            space: Configuration.value("VUE_APP_CONTENTFUL_SPACE_ID")
        });

        // Event bus
        window.enbwEventBus = new Vue({
            store,
            router
        });

        const app = new Vue({
            router,
            store,
            render: (h) => h(App)
        }).$mount("#app");

        return { app, router, store };
    }
    return window.location.href = "/cookies";
}

createApp();
