import Vue from "vue";
import Router from "vue-router";
import { createStore } from "./store";

Vue.use(Router);

// Helper functions
const requireAuth = (to, from, next, Store) => {
    if (Store.getters["AuthenticationStore/getAuthToken"] !== null
        && Store.getters["AuthenticationStore/getAuthToken"] !== "") {
        return next();
    }
    return next(`/?view=login&red=${to.fullPath}`);
};

const denyAuth = (to, from, next, Store) => {
    if (Store.getters["AuthenticationStore/getAuthToken"] !== null
        && Store.getters["AuthenticationStore/getAuthToken"] !== "") {
        // Has Order -> Bestellungen
        if (Store.getters["UserStore/getUserOrderStatus"]) {
            return next("/bestellungen");
        }
        // Has no Order -> Angebote
        return next("/angebote");
    }
    // Allow page access
    return next();
};

const router = new Router({
    mode: "history", // get rid of hash based routes and switch to history mode
    routes: [
        {
            path: "/",
            name: "VSplashscreen",
            component: () => import(/* webpackChunkName: "customer-center" */ "./views/splashscreen/Splashscreen.vue"),
            meta: {
                layout: "minimum/Minimum",
                title: "EnBW - #Dein e-Auto Kundenportal",
                routeType: "denyAuth"
            }
        },
        {
            path: "/logout",
            name: "VLogout",
            component: () => import(/* webpackChunkName: "customer-center" */ "./views/logout/Logout.vue"),
            meta: {
                layout: "minimum/Minimum",
                title: "EnBW - #Dein e-Auto Kundenportal"
            }
        },
        {
            path: "/bestellungen",
            name: "VOrders",
            component: () => import(/* webpackChunkName: "customer-center" */ "./views/orders/Orders.vue"),
            meta: {
                routeType: "requireAuth",
                layout: "content-page/ContentPage",
                title: "EnBW - #Dein e-Auto Kundenportal"
            },
            props: true
        },
        {
            path: "/bestellungen/:id",
            name: "VOrderDetails",
            component: () => import(/* webpackChunkName: "customer-center" */ "./views/order-detail/OrderDetail.vue"),
            meta: {
                routeType: "requireAuth",
                layout: "content-page/ContentPage",
                title: "EnBW - #Dein e-Auto Kundenportal"
            },
            props: true
        },
        {
            path: "/bestellungen/:id/versteuerungsangaben",
            name: "VTaxation",
            component: () => import(/* webpackChunkName: "customer-center" */ "./views/taxation/Taxation.vue"),
            meta: {
                routeType: "requireAuth",
                layout: "content-page/ContentPage",
                title: "EnBW - #Dein e-Auto Kundenportal"
            }
        },
        {
            path: "/angebote",
            name: "VOffers",
            component: () => import(/* webpackChunkName: "customer-center" */ "./views/offers/Offers.vue"),
            meta: {
                routeType: "requireAuth",
                layout: "content-page/ContentPage",
                title: "EnBW - #Dein e-Auto Kundenportal"
            }
        },
        {
            path: "/angebote/vergleichen",
            name: "VOffersCompare",
            component: () => import(/* webpackChunkName: "customer-center" */ "./views/offers-compare/OffersCompare.vue"),
            meta: {
                routeType: "requireAuth",
                layout: "content-page/ContentPage",
                title: "EnBW - #Dein e-Auto Kundenportal"
            }
        },
        {
            path: "/angebote/:id",
            name: "VOfferDetail",
            component: () => import(/* webpackChunkName: "customer-center" */ "./views/offer-detail/OfferDetail.vue"),
            meta: {
                routeType: "requireAuth",
                layout: "content-page/ContentPage",
                title: "EnBW - #Dein e-Auto Kundenportal"
            }
        },
        {
            path: "/angebote/:id/checkout",
            name: "VCheckout",
            component: () => import(/* webpackChunkName: "customer-center" */ "./views/checkout/Checkout.vue"),
            meta: {
                routeType: "requireAuth",
                layout: "content-page/ContentPage",
                title: "EnBW - #Dein e-Auto Kundenportal"
            }
        },
        {
            path: "/angebote/:id/checkout/confirmation",
            name: "VCheckoutConfirmation",
            component: () => import(/* webpackChunkName: "customer-center" */ "./views/checkout-confirmation/CheckoutConfirmation.vue"),
            meta: {
                routeType: "requireAuth",
                layout: "content-page/ContentPage",
                title: "EnBW - #Dein e-Auto Kundenportal"
            }
        },
        {
            path: "/profil",
            name: "VProfile",
            component: () => import(/* webpackChunkName: "customer-center" */ "./views/profile/Profile.vue"),
            meta: {
                routeType: "requireAuth",
                layout: "content-page/ContentPage",
                title: "EnBW - #Dein e-Auto Kundenportal"
            }
        },
        {
            path: "/leistungen",
            name: "VServices",
            component: () => import(/* webpackChunkName: "customer-center" */ "./views/services/Services.vue"),
            meta: {
                routeType: "requireAuth",
                layout: "content-page/ContentPage",
                title: "EnBW - #Dein e-Auto Kundenportal"
            }
        },
        {
            path: "/faq",
            name: "VFaq",
            component: () => import(/* webpackChunkName: "customer-center" */ "./views/faq/Faq.vue"),
            meta: {
                routeType: "requireAuth",
                layout: "content-page/ContentPage",
                title: "EnBW - #Dein e-Auto Kundenportal"
            }
        },
        {
            path: "/kontakt",
            name: "VServiceAndContact",
            component: () => import(/* webpackChunkName: "customer-center" */ "./views/service-and-contact/ServiceAndContact.vue"),
            meta: {
                routeType: "requireAuth",
                layout: "content-page/ContentPage",
                title: "EnBW - #Dein e-Auto Kundenportal"
            }
        }
    ],
    // eslint-disable-next-line consistent-return
    scrollBehavior(to) {
        if (to.name === "VCheckout" || to.name === "VCheckoutConfirmation") {
            return { x: 0, y: 200 };
        }
    }

});

router.beforeEach((to, from, next) => {
    // Redirect for old order route
    if (to.path === "/bestellung") {
        return next("/bestellungen");
    }

    // Close navigation if opened
    if (document.querySelector(".mobile-nav.visible .icon--close")) {
        document.querySelector(".mobile-nav.visible .icon--close").click();
    }

    // Remove Notificationbox when present
    const Notification = document.querySelector(".CNotification");
    if (Notification) Notification.parentElement.removeChild(Notification);

    const Store = createStore();
    if (to.meta.routeType) {
        if (to.meta.routeType === "requireAuth") {
            return requireAuth(to, from, next, Store);
        }
        if (to.meta.routeType === "denyAuth") {
            return denyAuth(to, from, next, Store);
        }
    }
    return next();
});

/**
 * @returns {VueRouter}
 */
export function createRouter() {
    return router;
}
