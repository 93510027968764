import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import createLogger from "./logger";

// import namespaced store modules
import storeModules from "./modules";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

/**
 * @returns {Store<any>}
 */
export function createStore() {
    return new Vuex.Store({
        modules: storeModules,
        strict: debug,
        plugins: /* istanbul ignore next */debug
            ? [createPersistedState(), createLogger()]
            : [createPersistedState()]
    });
}
