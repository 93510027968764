const initialState = () => ({
    location: null,
    offer: null,
    selectedVariant: null,
    selectedPayment: null
});

// define getters
const getters = {
    getCheckoutData(state) {
        return state;
    },
    getCheckoutOfferImage(state) {
        return state.selectedVariant?.images[0] || state.offer?.image;
    }
};

// define mutations
const mutations = {
    updateCheckoutData(state, payload) {
        state.location = payload.data.location;
        state.offer = payload.data.offer;
        state.selectedVariant = payload.data.selectedVariant;
        state.selectedPayment = payload.data.selectedPayment;
    },
    resetStore(state) {
        const s = initialState();
        Object.keys(s).forEach((key) => {
            state[key] = s[key];
        });
    }
};

// export namespaced store module
export default {
    getters,
    mutations,
    namespaced: true,
    state: initialState()
};
