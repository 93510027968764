import axios from "axios";
import Configuration from "@/config/config";
import resetStoreMixin from "@/mixins/resetStore.mixin";
import helperMixin from "@/mixins/helper.mixin";

const apiEndpoint = Configuration.value("VUE_APP_BACKEND_SERVICE");

const logoutUser = (path = "/logout") => {
    // Reset Store
    resetStoreMixin.methods.resetStore(window.enbwEventBus.$store);
    // Redirect to splashscreen
    window.enbwEventBus.$router.push(path);
};

const handleError = (error, _this) => {
    const redirect = helperMixin.methods.getParams("red")
        ? decodeURIComponent(helperMixin.methods.getParams("red"))
        : null;
    if (error.status === 401 && error.data && error.data.message && error.data.message.includes("Expired")) {
        // Logout with expired session error message
        logoutUser(redirect ? `/?view=login&session_expired&red=${redirect}` : "/?view=login&session_expired", _this);
    }
    if (error.status === 401 && error.data && error.data.message && (error.data.message.includes("Token not found") || error.data.message.includes("Invalid"))) {
        // Reset stores and logout with generic error message
        logoutUser(redirect ? `/?view=login&generic_error&red=${redirect}` : "/?view=login&generic_error", _this);
    }
    return error;
};

export default {
    methods: {
        // eslint-disable-next-line consistent-return
        async apiRequest(type = "post", path = "", payload = {}, token = null) {
            token = (token) ? `bearer ${token}` : null;
            if (type === "post" || type === "put") {
                return axios[type](apiEndpoint + path, { ...payload }, {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8",
                        Authorization: token
                    }
                })
                    .then((response) => response)
                    .catch((e) => handleError(e.response, this));
            }
            if (type === "get" || type === "delete") {
                return axios[type](apiEndpoint + path, {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8",
                        Authorization: token
                    }
                })
                    .then((response) => response)
                    .catch((e) => handleError(e.response, this));
            }
        }
    }
};
