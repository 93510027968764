export default {
    methods: {
        resetStore(Store) {
            // Reset AuthenticationStore
            Store.commit({
                type: "AuthenticationStore/resetStore"
            });
            // Reset UserStore
            Store.commit({
                type: "UserStore/resetStore"
            });
            // Reset PayrollStore
            Store.commit({
                type: "PayrollStore/resetStore"
            });
            // Reset CheckoutStore
            Store.commit({
                type: "CheckoutStore/resetStore"
            });
            // Reset ApplicationStore
            Store.commit({
                type: "ApplicationStore/resetStore"
            });
            // Clear Auth Store Interval
            if (window.sessionInterval) window.clearInterval(window.sessionInterval);
        }
    }
};
