// Sentry
import * as Sentry from "@sentry/browser";

const defaultPayroll = {
    childAllowance: 0,
    children: false,
    churchTax: true,
    distanceToWorkPlace: 15,
    federalHealthInsurance: true,
    federalHealthInsuranceAdditionalContribution: 0.7,
    monthlySalary: 3000,
    pensionInsurance: true,
    privateHealthInsuranceContribution: 0,
    privateHealthInsuranceGrant: false,
    privateNursingInsuranceContribution: 0,
    state: 1,
    taxationOptionRides: 1,
    taxationOption: 0.03,
    taxClass: 1,
    unemploymentInsurance: true
};

const initialState = () => ({
    ...defaultPayroll
});

// define getters
const getters = {
    getPayroll(state) {
        return state;
    },
    getDefaultPayroll() {
        return defaultPayroll;
    }
};

// define mutations
const mutations = {
    // eslint-disable-next-line consistent-return
    setPayroll(state, payload) {
        if (!payload) return Sentry.captureException("setPayroll empty payload", payload);
        state.childAllowance = Number(payload.data.childAllowance);
        state.children = payload.data.children;
        state.churchTax = payload.data.churchTax;
        state.distanceToWorkPlace = Number(payload.data.companyTripDistance.replace(/,/g, "."));
        state.federalHealthInsurance = payload.data.federalHealthInsurance;
        state.federalHealthInsuranceAdditionalContribution = Number(payload.data.federalHealthInsuranceAdditionalContribution.replace(/,/g, "."));
        state.monthlySalary = Number(payload.data.monthlySalary.replace(/,/g, "."));
        state.pensionInsurance = payload.data.pensionInsurance;
        state.privateHealthInsuranceContribution = Number(payload.data.privateHealthInsuranceContribution.replace(/,/g, "."));
        state.privateHealthInsuranceGrant = payload.data.privateHealthInsuranceGrant;
        state.privateNursingInsuranceContribution = Number(payload.data.privateNursingInsuranceContribution.replace(/,/g, "."));
        state.state = +payload.data.state;
        // eslint-disable-next-line max-len
        state.taxationOptionRides = payload.data.taxationOptionRides === null ? payload.data.taxationOptionRides : Number(payload.data.taxationOptionRides);
        state.taxationOption = Number(payload.data.taxationOption);
        state.taxClass = payload.data.taxClass;
        state.unemploymentInsurance = payload.data.unemploymentInsurance;
    },
    resetStore(state) {
        const s = initialState();
        Object.keys(s).forEach((key) => {
            state[key] = s[key];
        });
    }
};

// export namespaced store module
export default {
    getters,
    mutations,
    namespaced: true,
    state: initialState()
};
