import ApplicationStore from "./Application";
import AuthenticationStore from "./Authentication";
import CheckoutStore from "./Checkout";
import ContentStore from "./Content";
import OrderStore from "./Order";
import PayrollStore from "./Payroll";
import UserStore from "./User";

export default {
    ApplicationStore,
    AuthenticationStore,
    CheckoutStore,
    ContentStore,
    OrderStore,
    PayrollStore,
    UserStore
};
