export const appMessages = {
    successMessages: {
        defaultChanged: "Deine Änderungen wurden erfolgreich gespeichert.",
        passwordChanged: "Dein Passwort wurde erfolgreich geändert.",
        personalInformationChanged: "Deine persönlichen Daten wurden erfolgreich geändert.",
        resendActivationMail: "Die Bestätigungsmail wurde erfolgreich an Deine E-Mail Adresse gesendet."
    },
    errorMessages: {
        accountDisabled: "Damit du das Kundenkonto vollständig nutzen kannst, bestätige bitte den Link in der Bestätigungs-E-Mail. Solltest Du die E-Mail in Deinem Posteingang nicht finden, überprüfe bitte Deinen Spam-Ordner.",
        badCredentials: "Die Kombination aus Benutzername und Passwort ist nicht gültig.",
        default: "Ups... Da ist irgendetwas schief gelaufen. Bitte versuche es noch einmal.",
        invalidEmail: "Bitte verwende deine EnBW-Mailadresse.",
        invalidPasswordSyntax: "Das Passwort muss mindestens einen Großbuchstaben, einen Kleinbuchstaben, eine Zahl und ein Sonderzeichen enthalten.",
        invalidPasswordConfirm: "Die eingegebenen Passwörter stimmen nicht überein.",
        resendActivationMail: "Leider konnte die E-Mail nicht versendet werden. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.",
        requiredFormValue: "Dieses Feld ist ein Pflichtfeld.",
        server: "Leider ist ein Fehler aufgetreten. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut."
    }
};
