export default {
    name: "CCookieLayer",
    props: {
        cookieName: {
            default: "cookie_accepted",
            required: false,
            type: String
        },
        expireDate: {
            default() {
                const now = new Date();
                return new Date(now.setMonth(now.getMonth() + 1));
            },
            required: false,
            type: Date
        }
    },
    data() {
        return {
            showLayer: false
        };
    },
    beforeMount() {
        if (!document) return;

        const cookieAccepted = Boolean(
            document.cookie.match(new RegExp(this.cookieName))
        );
        if (cookieAccepted) return;

        this.toggleLayer();
    },
    mounted() {
        const bodyElement = document.getElementsByTagName("body");
        const cookieLayer = document.getElementsByClassName("cCookieLayer");
        const cookieLayerHeight = cookieLayer[0];

        if (cookieLayer.length >= 1) {
            bodyElement[0].style.paddingTop = `${cookieLayerHeight.offsetHeight / 10}rem`;
        }
    },
    methods: {
        toggleLayer() {
            this.showLayer = !this.showLayer;
        },
        setCookie() {
            document.cookie = `${
                this.cookieName
            }=true; path=/; domain=${this.getDomain()}; expires=${this.expireDate.toString()}`;
            this.toggleLayer();
        },
        getDomain() {
            const domain = document.location.hostname
                .split(".")
                .slice(-2)
                .join(".");
            return domain === "localhost" ? domain : `.${domain}`;
        },
        hidePadding() {
            const bodyElement = document.getElementsByTagName("body");
            const cookieLayer = document.getElementsByClassName(
                "cCookieLayer"
            );
            if (cookieLayer.length >= 1) {
                bodyElement[0].style.paddingTop = "0";
            }
        }
    }
};
